@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
}

.RightPanelOuterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--bg-panel);
}

.leftPanel {
    width: 189px;
    min-width: 189px;
    background-color: var(--bg-panel);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 44px;
    border-right: 1px solid #2a2c30;
}

.rightPanel {
    background-color: var(--bg-panel);
    width: calc(100vw - 189px);
    max-width: 1236px;
}

.transactionContainer, .transactionContainer * {
    background-color: #04070D !important;
}

.transactionContainer * {
    font-family: var(--font-family) !important;
}

.vectorIcon {
    width: 120px;
    height: 36px;
}

.leftMenu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 188px;
}

.leftMenu {
    background-color: #04070D;
}

.leftOption {
    font-size: 14px;
    font-family: var(--font-family);
    height: 17px;
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: center;
    display: flex;
    font-style: normal;
    font-weight: 500;
    padding-left: 35px;
    cursor: pointer;
}

.leftOption:hover {
    color: rgba(255, 255, 255, 0.8);
}

.leftOptionUl {
    padding-left: 0;
}

.leftOption:first-child {
    margin-top: 71px;
}

.leftOption:nth-child(2) {
    background-color: #32363D !important;
}

.leftOption:nth-child(2), .leftOption:nth-child(3) {
    margin-top: 10px;
}

.leftMenu li {
    margin: 20px 0;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 14px;
    transition: color 0.2s ease;
    background-color: var(--bg-panel);
}

li {
    background-color: var(--bg-panel);
}

.leftMenu li:hover {
    color: rgba(255, 255, 255, 0.8);
}

.activeMenuItem {
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
}

.transactionContainer {
    padding: 20px;
    color: #FFFFFF;
    font-family: sans-serif;
}

/* Transactions Title */
.transactionsTitle {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 27px;
}

/* Today label */
.transactionsToday {
    color: rgba(255, 255, 255, 0.70);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 10px;
}

/* Container for all transaction rows */
.transactionList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.transactionsDayTitle {
    color: #B4B5B6;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    margin-bottom: 10px;
}

.transactionRowOuterContainer {
    max-width: 1156px;
    margin-bottom: 15px;
}

/* Transaction Row */
.transactionRow {
    display: flex;
    align-items: center;
    padding-left: 2.6%;
    padding-right: 16px;
    border-radius: 15px;
    height: 60px;
    background-color: #1C1C1C;
    max-width: 1156px;
}

/* New rule: when a row is expanded, remove bottom border radius */
.transactionRow.expandedRow {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.directionArrow {
    flex: 0 0 20px;
    width: 10px;
    height: 12px;
    margin-right: 8px;
}

.txType {
    flex: 0 0 18.7%;
    margin-right: 12px;
    font-weight: 500;
    color: #D2D2D2;
}

.uIcon {
    flex: 0 0 30px;
    width: 29px;
    height: 29px;
    margin-right: 6px;
}

.amount {
    flex: 0 0 auto;
    margin-left: 5px;
    text-align: right;
    margin-right: 0px !important;
}

.amountBox {
    width: 228.41px
}

.usdi {
    flex: 0 0 17.122%;
    color: #8D8D8D;
}

.to {
    flex: 0 0 26px;
    color: #8D8D8D;
}

.toAddress {
    flex: 0 0 22.221%;
    color: #FFF;
}

@media (max-width: 1139px) {
    .toAddress {
        flex: 0 0 18%;
        color: #FFF;
    }
}

.time {
    flex: 0 0 11.4%;
    color: #FFF;
}

.statusCompleted,
.statusPending {
    flex: 0 0 15%;
}

.expandIcon {
    flex: 0 0 1.7301%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.transactionRow, .transactionRow * {
    background-color: #1C1C1C !important;
}

.lineWrapper {
    background-color: #1C1C1C !important;
    width: 100%;
    /*height: 1px !important;*/
    border: #1C1C1C;
}

.dividerLineFull {
    max-width: 1116px;
    margin-inline: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: #3F3F3F !important;
    /*margin: 0 auto;*/
}

.dividerLineFull2 {
    width: calc(100% - 40px);
    height: 1px;
    background-color: #3F3F3F !important;
    margin-inline: 20px;
}

/* Arrow styling */
.directionArrow {
    width: 10px;
    height: 12px;
    margin-right: 8px;
}

/* TxType styling */
.txType {
    margin-right: 12px;
    font-weight: 500;
}

/* U Icon styling */
.uIcon {
    width: 29px;
    height: 29px;
    margin-right: 6px;
}

/* Amount styling */
.amount {
    margin-right: 16px;
    text-align: right;
}

/* "To:" label */
.toLabel {
    margin-right: 4px;
    color: #888888;
}

/* Address styling */
.address {
    margin-right: 16px;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Status Colors */
.statusPending {
    color: #15A6F4;
}

.statusCompleted {
    color: #6CE3C4;
}

/* Expanded section for details */
.expandedContainer {
    background-color: #1C1C1C !important;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-inline: 20px;
    padding-top: 16px;
    padding-bottom: 20px;
    max-width: 1156px;
    display: flex;
    gap: 9px;
    color: #FFFFFF;
    font-family: var(--font-family);
}

.expandedInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #252525 !important;
    border-radius: 16px;
    padding: 18px 25px 11px 28px;
}

.expandedInfo *, .expandedProgress * {
    background-color: #252525 !important;
}

.expandedProgress {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #252525 !important;
    border-radius: 16px;
    padding: 18px 13px 19px 25px;
    justify-content: space-between;
}

.expandedSectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
    color: #FFFFFF;
}

.expandedText {
    font-size: 12px;
    font-weight: 400;
    color: #BBB;
    display: flex;
    justify-content: space-between;
}

.fixedLabel {
    /* Optional label styling */
}

.fetchedData {
    color: white;
}

.expandedNote {
    font-size: 10px;
    font-weight: 400;
    color: #929292;
    margin-top: 1px;
    color: rgba(255, 255, 255, 0.50);
    font-style: normal;
    line-height: normal;
}

/* Progress Items */
.progressItem {
    font-size: 15px;
    color: #FFFFFF;
    /*margin-bottom: 5px;*/
    line-height: 14px;
    font-style: normal;
    font-weight: 500;
}
.progressItem:nth-of-type(2) {
    margin-top: -10px;
}
.progressItemTime {
    margin-top: 10px;
    color: #929292;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 10.5px; /* 140.93% */
}

.progressItemContainer {
    display: flex;
}

.progressItemContainerLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 183px;
    margin-left: 8px;
}


.progressItemContainerRight {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 36px;
    margin-top: 8px;
    padding-right:22px;
}

.operatorWalletContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.operatorWalletContainerLeft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.timelineCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #4F4F4F; /* 圆点的颜色，可自定 */
  z-index: 1; /* 确保圆点在竖线上方 */
}

/* 步骤之间的竖线 */
.timelineLine {
  width: 2px;
  height: 40px; /* 你可以根据需要调整步与步之间的距离 */
  background-color: #e04141 !important;
  margin: -2px 0; /* 避免竖线与圆点的边缘出现空隙 */
}
.operatorWalletContainerLeft img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}

.c2cWallet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 15px;
    gap: 10px;
    font-size: 15px;
}
.imgContainer{
    display: flex;
    gap: 5px;
}
.imgContainer img{
    cursor: pointer;
    width: 10px;
    height: 10px;
}
.greyWalletAddress {
    color: #929292;

    font-size: 10.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 10.5px; /* 140.93% */
}

/* New Progress Buttons Container and Buttons */
.progressButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.progressButton2, .progressButton3 {
    width:100%;
    height: 45px;
    background-color: #2B2C33 !important;
    color: #FFFFFF;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 16.5px */
}

.progressButton2 {
    background-color: white !important;
    color: #1A1A1A;
}

.progressButton2:hover {
    background-color: #DEDBE5 !important;
}
.dividerLineFull2 {
    width: 100%;
    height: 1px;
    background-color: #3F3F3F !important;
    margin-inline: 0;
    margin-top: 4px;
}
/* Animation for expanding/folding the expanded container */
.expandedEnter {
  max-height: 0;
  opacity: 0;
}

.expandedEnterActive {
  max-height: 800px; /* Adjust as necessary */
  opacity: 1;
  transition: max-height 200ms ease-out, opacity 200ms ease-out;
}

.expandedExit {
  max-height: 500px;
  opacity: 1;
}

.expandedExitActive {
  max-height: 0;
  opacity: 0;
  transition: max-height 200ms ease-out, opacity 200ms ease-out;
}

/* Responsive adjustments */
@media (max-width: 1000px) {
    .container {
        flex-direction: column;
        padding-inline: 0;
        display: flex;
    }

    .leftPanel {
        display: none;
    }

    .rightPanel {
        width: 100%;
    }
}

@media (max-width: 1166px) {
    .leftPanel {
        width: 161px !important;
        min-width: 161px;
    }

    .leftMenu, .leftMenu ul {
        width: 160px !important;
    }

    .leftOption {
        margin-left: -14px !important;
    }
}
