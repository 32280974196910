@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');

:root {
    --dynamic-padding: 0px;
}
@media(max-width: 800px){

}

* {
    font-family: Codee Sans Mono, monospace;
    font-size: 14px;
    line-height: 22.71px;
}

@media (min-width: 600px){
    .container.mt-5 {
    padding-left: 1.44vw !important;
    padding-right: 1.44vw !important;

}
}
.container.mt-5 {
    padding-left: 5.44vw;
    padding-right: 5.44vw;
}

.green-title {
    width: 11vw;
    height: auto;
}

.monitor {
    width: 6.18vw;
    height: auto;
    margin-left: 9px;
}

@media (max-width: 600px) {
    .container.mt-5 {
        /*padding: 32px;*/
        padding: 0;
        background-color: #000000;
        padding-left: 7vw;
        margin-top: 0;
        padding-right: 7vw;
    }

    .h {
        padding-bottom: 5px;
    }

    .green-title {
        padding-bottom: 5px;
        width: 38.71vw;
        height: auto;
        margin-right: 50px;
    }

    .mb-3 {
        margin-bottom: 2.48vh !important;
    }

    .monitor {
        width: 80px !important;
        height: auto;
    }

    .potential-profolio {
        padding-top: 20px;
    }

    .navbar-flexbox {
        justify-content: space-between !important;
    }

    .nav-link
    .current-profolio {
        padding: 12px 8px !important;
    }

    .table-responsive {
        margin-bottom: 15px !important;
    }

    .title-box {
        flex-wrap: wrap;
        background-color: black;
    }
    .h{
        background-color: black;
    }
    .pagination{
        background-color: black;
    }
}

.title-box {
    margin-bottom: 2.48vh;
}

/*adjust the page link padding based on screen size*/


@media (max-width: 574px) {
    .page-link {
        padding-left: var(--dynamic-padding);
        padding-right: var(--dynamic-padding);
    }
}


/* Center the table container and ensure it fits within the viewport */
.table-responsive {
    background-color: #071112;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12.387px; /* Rounded corners */
    padding-left: 2vw;
    padding-right: 2vw;
    border: 1.548px solid #1F464D;
    margin-bottom: 2.89vh;
}

.portfolio-table {
    padding-left: 1.5vw !important;
    padding-right: 1.5vw !important;
}

/*For potential portfolio*/
.potential-table {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
}

.first-row:first-child {
    text-align: left !important;
}

.potential-data {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    text-align: center !important;
}

.current-profolio {
    text-align: left;
}

.title {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    font-size: 12px !important;
    text-align: center !important;
}

.h {
    margin-top: -60px;

}

/* Ensure the table fits within the viewport width */
.table-fit {
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
}

/* Ensure the spinner is centered */
.spinner-border {
    width: 3rem;
    height: 3rem;
}

/* table header */
.table thead th {
    background-color: #071112;
    color: #FFFFFF80;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 800;
    line-height: 22.71px;
    text-align: center;
    border: none;
    padding: 0 20px 5px;

}

.table thead th {
    text-align: left;

}

/*.table tbody :first-child{*/
/*    text-align: left;*/
/*}*/
/* Table font size，and other properties */
.table tbody td {
    background-color: #071112;
    color: #FFFFFFCC;
    font-size: 14px;
    font-weight: 100;
    line-height: 22.71px;
    text-align: left;
    border: none;
    padding: 15px 20px;
    vertical-align: middle;
}

.navbar-flexbox {
    display: flex;
}

.navbar-flexbox {

    /*  padding: 0;*/
    /*margin: 0;*/
}

@media (max-width: 1000px) {
    .navbar-flexbox {
        flex-wrap: wrap; /* Allow elements to wrap to the next row */
    }
}

.table tbody tr {
    border-bottom: 1px solid #FFFFFF4D; /* Subtle horizontal border */
}

.table tbody tr:last-child {
    border-bottom: none !important;
}

.table tbody td:first-child {
    padding-left: 0;
}

.table thead th:first-child {
    padding-left: 0;
}

.table thead tr {
    border-top: none; /* Subtle horizontal border */
    border-bottom: 1px solid #FFFFFF4D; /* Subtle horizontal border */
}

.sort-arrows::after {
    content: "▲▼"; /* Both arrows displayed */
    font-size: 0.8em;
    margin-left: 5px;
    color: #ccc; /* Default color for both arrows */
}

.icon-container {
    position: relative;
    display: inline-block; /* Ensures the container takes up only as much space as needed */
    vertical-align: middle;
}

.icon-top, .icon-bottom {
    position: absolute;
    top: -7px;
    left: 0;
}

.ascending-sort {
    cursor: pointer;
    transform: rotate(180deg);
}

.descending-sort {
    cursor: pointer;
}

.icon-grey {
    color: #8e8e8e;
    cursor: pointer;
}


.page-link {
    background-color: #071112CC; /* Same dark background */
    color: rgba(255, 255, 255, 0.50);
    border: 1.548px solid #1F464D;
    /*font-size: 12px;*/
}

.page-link:hover {
    background-color: #071112; /* Slightly lighter on hover */
    color: #FFFFFFCC; /* Light green on hover */
    border-color: #1F464D;
}

.page-item.active .page-link {
    background-color: #1F464D; /* Active background */
    color: #FFFFFFCC; /* Active text color */
    border-color: #1F464D; /* Border color for active item */
}

.page-item.disabled .page-link {
    background-color: #071112CC;
    color: rgba(255, 255, 255, 0.50);
    cursor: not-allowed;
    border: 1.548px solid #1F464D;
}

.page-link:focus {
    outline: none;
    box-shadow: none;
}

.time-stamp {
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-top: 0px;
    padding-right: 20px;
}

@media (max-width: 600px) {
    .time-stamp {
        font-size: 10px;
    }

    .page-link {
        font-size: 11px;
    }
}

.table-responsive {
    scrollbar-color: #888 #0A1A20; /* Scrollbar thumb color, scrollbar track color */
    scrollbar-width: thin; /* Optional: scrollbar width */
}


@keyframes changeColor {
  0% {
    background-color: #183336;
  }
  50% {
    background-color: #102224;
  }
  100% {
    background-color: #183336;
  }
}

.gray-box {
    width: 100%;
    /*width: 90px;*/
    height: 20px;
    flex-shrink: 0;
    border-radius: 7px;
    background: #102224;
    animation: changeColor  2s ease-in-out infinite;
}
@media  (max-width: 800px)  {
    #solana-tab{
    background-color: black !important;
        /*margin-bottom:0 !important;*/
}
    #solana-tab-lower{
                margin-bottom:0 !important;
        margin-top: 20px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 15px !important;
    }

}
