/* LeftPanel.module.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

:root {
  --font-family: "Poppins", sans-serif;
  --bg-panel: #04070D; /* Or whatever your panel background variable is */
}

/* The overall left panel container */
.leftPanel {
  width: 189px;
  min-width: 189px;
  background-color: var(--bg-panel);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
  border-right: 1px solid #2a2c30;
}

.vectorIcon {
  width: 120px;
  height: 36px;
}

/* Your menu container */
.leftMenu {
  background-color: #04070D;
}

/* The UL inside the menu */
.leftMenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 188px;
}

/* Each menu item (My Assets / Transactions / Invite Friends, etc.) */
.leftOption, .accountContainer {
  font-size: 14px;
  font-family: var(--font-family);
  height: 17px;
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: center;
  display: flex;
  font-style: normal;
  font-weight: 400;
  padding-left: 35px;
  cursor: pointer;
  color: #ffffff;
  background-color: var(--bg-panel);
  transition: color 0.2s ease;
}

.leftOption:hover, .accountContainer:hover {
  color: rgba(255, 255, 255, 0.8);
}


.accountContainer{
  width: 130px;
  height: 41px;
  margin-left: 29px;
  padding-left: 15px;
  border-radius: 13px;
  gap: 0;
  margin-top: 200%;
  background-color: #32363D;
}
.accountContainer *{
    background-color: #32363D;
}
.accountContainer:hover{
  background-color: #65676A;
}
.accountContainer:hover img {
  background-color: #65676A;
}
.leftOptionUl {
  padding-left: 0;
}

/* The first <li> can get extra top margin, etc. */
.leftOption:first-child {
  margin-top: 71px;
}



.leftOption:nth-child(2), .leftOption:nth-child(3), .leftOption:nth-child(4),  .leftOption:nth-child(5) {
  margin-top: 10px;
}

.activeMenuItem {
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
}
.activeGrey {
  background-color: #32363D !important;
}
.activeGrey *{
    background-color: #32363D !important;

}
@media (max-width: 1000px) {
  .leftPanel {
    display: none;
  }
}
