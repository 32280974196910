@import url('https://fonts.googleapis.com/css2?family=Codee+Sans+Mono:wght@400&display=swap');


body {
    margin: 0;
}

* {
    font-size: 14px;
    font-family: "Codee Sans Mono", monospace;
    margin: 0;
    padding: 0;
    text-decoration: none !important; 
 
}
.menu_bar {
    background-color: rgba(17, 27, 28, 0.80);
    margin-left: -17px;
}

.vectorIcon {
    top: 0px;
    left: 0px;
    width: 7.56vw;
    height: auto;
    /*margin-left: -5px;*/
    margin-top: 23px;
}


.navBar {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    margin-left: 15.87vw;
    gap: 2.1vw;
    padding:0;
}


.nav-link {
    color: #FFFFFF;
    font-size: 16px;
    padding-bottom: 5px;
    transition: all 0.3s ease;
    border-bottom: none;
    text-decoration: none; 
}


.nav-link.active-link {

    font-weight: bold;
    line-height: 0.4;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    border-bottom: none;
    text-decoration: none;  
}

li {
    margin-top: 43px;
    height: 50px;
    list-style-type: none;
}
li .nav-link{
    position:fixed;
    top:0;
}
.sideline {
    position: fixed;
    top: 0;
    right: 43.5vw;
    height: 0;
    z-index: 1000;
    display: none;
}
.sidebar {
    position: fixed;
    /*opacity:0;*/
    top: -5px;
    right: 0;
    height: 105vh;
    width: 52vw;
    z-index: 999;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px 0px 0px 6px;
    flex-direction: column;
    border: 1px solid rgba(69, 79, 102, 0.6);
    border-top: 0px;
    display: none;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(7, 17, 18, 0.8);
    /*transition-delay: 0s;*/

    /*transition: all 0.5s ease;*/
}

.sidebar li {
    width: 100%;
    background-color: rgba(7, 17, 18, .1);
    position: relative;
    text-align: left;
    height: 1px;
}
li{
    /*background-color: black;*/
}
img.menu {
    margin-left: 20px;
    cursor: pointer;
    position: absolute;
    right: 4.35vw;
    margin-top: 20px;
    width: 4.1vw;
    height: auto;

}

img.close {
    cursor: pointer;
    margin-right: 3px;
    position: absolute;
    right: 8%;
    margin-top: 7px;
    width: 16px;
    height: auto;
    background-color: rgba(7, 17, 18, .1);

}

.hideOnMobile{
    background-color: #0C0F0E;
}
@media(max-width: 800px){
   .hideOnMobile{
    background-color: black;
}
}
@media (max-width: 600px) {
    .hideOnMobile {
        display: none;
    }
    /*make icon smaller using mobile*/
    .vectorIcon {
        width: 14.8vw;
        height: auto;
        margin-left: -2.8vw;
    }
    .sidebar-link{
        padding-top:5px !important;
        font-size: 5px;
    }
    .navBar{
        background-color: black;
    }
}


@media (min-width: 600px) {
    .hideOnDesktop {
        display: none;
    }
}

/*testing close button*/
.btn_burg_menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-items: flex-start;
  }

  .line {
    position: relative;
    height: 2.5px;
    width: 18px;
    z-index: 30;
    transition: all 0.4s ease;
  }

  .line:nth-child(1) {
    background: linear-gradient(180deg, #01f663 0%, #4acf92 100.01%);
  }

  .line:nth-child(2) {
    background: linear-gradient(180deg, #4acf92 0%, #1ac1c0 100%);
  }

  .line:nth-child(3) {
    background: linear-gradient(180deg, #1ac1c0 0%, #00e4fe 100.01%);
  }