.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #333;
  color: #ddd;
  font-family: 'Courier New', Courier, monospace;
  margin-top: 2.89vh;
  padding: 3.08vh 20px 3.3vh;

}

.status {
  display: flex;
  align-items: center;
  flex: 1;
}

.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.status-text {
  font-size: 14px;
  white-space: nowrap;
  color:  rgba(255, 255, 255, 0.60);
}

.social-icons {
  display: flex;
  gap: 25px;
}

.social-icon i {
  font-size: 20px;
  color: red;
  transition: color 0.3s;
}
.twitter{
  margin-top: 3px;
}
.twitter:hover{
  content: url('../static_files/hover-x-twitter.svg')
}
.tele:hover{
  content: url('../static_files/hover-Telegram.svg')
}
.discord:hover{
  content: url('../static_files/hover-discord.svg')
}

.reddit{
  margin-top: -3px;
}
.reddit:hover{
  content: url('../static_files/hover-reddit.svg')
}
/* Responsive design for mobile */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 0;
    border-top:none;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .social-icons {
    margin-top: 10px;
    justify-content: center;
    width: 100%;
  }

  .social-icon {
    margin-left: 10px;
  }
}
