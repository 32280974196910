.button_wrapper {
    display: flex;
    justify-content: space-between; /* Space between the title and the button */
    align-items: center; /* Vertically align the title and button */
    margin-bottom: 20px; /* Adds space below the wrapper */
}

h3 {
    margin: 0; /* Ensures the heading has no extra margins */
    padding-left: 0; /* Aligns the heading to the left */
}

.usdi-box h3 {
    margin-bottom: 15px;
}

.button {
    color: #FFFFFFCC;
    background-color: #071112CC;
    border: 2px solid #1F464D;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    width: 17vw;
    height: 4vh;
}

@media ( max-width: 709px) {
    .button {
        width: 25vw;
        height: 4vh;
        font-size: 13px;
    }
}

@media ( max-width: 424px) {
    .button {
        padding-left: 3%;
        width: 30vw;
    }
}

/* Hover effect */
.button:hover {
    background-color: #0A1A1ACC;
    transform: scale(1.05);
}

/* Active/onclick effect */
.button:active {
    background-color: #0A1A1ACC;
    transform: scale(0.95);
}

/* Flashing effect during update */
.button.updating {
    animation: flash 1s linear infinite;
    cursor: not-allowed;
}

.button.calculating {
    animation: flash 1s linear infinite;
    cursor: not-allowed;
}

.button.distributing {
    animation: flash 1s linear infinite;
    cursor: not-allowed;
}

/* Flashing animation */
@keyframes flash {
    0% {
        background-color: #071112CC; /* Original background */
    }
    50% {
        background-color: #1F464D; /* Flashing color */
    }
    100% {
        background-color: #071112CC; /* Back to original */
    }
}

.status_dropdown {
    background-color: #071112CC;
    color: #FFFFFFCC;
    border-radius: 8px; /* Adjust the value to make corners more or less rounded */
    padding: 8px; /* Optional: Add padding to make the dropdown look better */
    border: 1.5px solid rgba(255, 255, 255, 0.30); /* Optional: Add a border to define the dropdown edges */
}

/*for pop out table for usdi holder info table cum dws*/
.popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    background-color: #071112CC;
    border: 1px solid #1F464D;
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.popup table {
    width: 100%;
    font-size: 12px;
}

.popup th,
.popup td {
    padding: 5px;
    text-align: left;
}

/* Updated styles for input boxes and Update button in the pop-up */
.popup-inputs {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.popup-inputs input {
    margin-right: 10px;
    padding: 5px 15px;
    width: 17vw;
    height: 4vh;
    color: #FFFFFFCC;
    background-color: #071112CC;
    border: 2px solid #1F464D;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
}

@media (max-width: 709px) {
    .popup-inputs input {
        width: 25vw;
        height: 4vh;
        font-size: 13px;
    }
}

@media (max-width: 424px) {
    .popup-inputs input {
        width: 30vw;
    }
}

/* Hover effect for input */
.popup-inputs input:hover {
    background-color: #0A1A1ACC;
    transform: scale(1.05);
}

/* Active/onclick effect for input */
.popup-inputs input:active {
    background-color: #0A1A1ACC;
    transform: scale(0.95);
}

/* Styles for the Update button in the pop-up */
.popup-inputs .button {
    padding: 5px 15px;
    width: 17vw;
    height: 4vh;
    font-size: 16px;
    margin-left: 5px;
}

@media (max-width: 709px) {
    .popup-inputs .button {
        width: 25vw;
        height: 4vh;
        font-size: 13px;
    }
}

@media (max-width: 424px) {
    .popup-inputs .button {
        width: 30vw;
    }
}

/* Error message styling */
.popup-error {
    color: red;
    margin-top: 5px;
}

/* Adjust the table within the pop-up */
.popup table {
    color: #FFFFFFCC;
}

.popup th {
    background-color: #1F464D;
    color: #FFFFFFCC;
}


/* End for pop out table for usdi holder info table cum dws*/
.update-icon {
    cursor: pointer;
    padding-left: 5px;

}

.modal-header {
    background-color: rgba(250, 250, 250, 0.8);
}

.modal-title {
    background-color: rgba(250, 250, 250, 0.8);
    color: black;
    margin-top:10px;
    margin-bottom: 20px;
}

.modal-body {
    background-color: rgba(250, 250, 250, 0.8)
}

.modal-body p {
    background-color: rgba(250, 250, 250, 0.8);
    color: black;
    max-width: 100%;
}

.modal-content{
    width:500px;
}

@media(max-width: 768px){
    .modal-content{
    width:100%;
}

}