
.div {
    font-size: 14.1px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.165px;
    background: linear-gradient(190deg, #668465 3.2%, #FFF 185.43%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    top: -1.6px;
}

.downloadCsv {
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.50);
}
.downloadCsv:hover{
    color: #FFFFFFCC;
}
.frameIcon {
    width: 20.6px;
    position: relative;
    height: 20.6px;
    overflow: hidden;
    flex-shrink: 0;
    top: -2.6px;
}

.downloadCsvParent:hover {
    color: #a4d3a2; /* Light green text color on hover */
    border-color: #a4d3a2; /* Light green border color on hover */
}

.downloadCsvParent {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 5.2px;
    font-size: 17px;
    cursor: pointer;
}
@media (max-width: 1000px) {
  .parent {
     flex-basis: 100%;
  }

  /*.element:nth-child(2) {*/
  /*  flex-basis: 100%; !* Make the second element take full width *!*/
  /*}*/
}
.div1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.165px;
    background: linear-gradient(349deg, #668465 35.2%, #FFF 240.24%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    top: -2.5px;
    left: -2px;
}
@media (max-width: 800px){
    .div, .div1{
        display: none;
    }
    .downloadCsv{
        color: rgba(255, 255, 255, 0.50);
        font-size: 10px;
    }
    .frameIcon{
        width: 15px;
        height: auto;
        top: -5px;
    }
}
.parent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5.2px;
    text-align: left;
    font-size: 16.52px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Abhaya Libre ExtraBold', serif;
    padding-right: 0px;

}

