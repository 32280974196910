@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Codee+Sans+Mono:wght@400&display=swap');

/*import font for Codee Sans Mono*/
@font-face {
    font-family: 'Codee Sans Mono';
    src: url('./tables/static_files/codee_sans_mono/fonts/CodeeSansMono-Regular.woff2') format('woff2'),
    url('./tables/static_files/codee_sans_mono/fonts/CodeeSansMono-Regular.woff') format('woff'),
    url('./tables/static_files/codee_sans_mono/fonts/CodeeSansMono-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}


body {
    margin: 0;
}
body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
    font-size: 14px;
    font-family: 'Codee Sans Mono', monospace;
    background-color: #0C0F0E;
    letter-spacing: 0.17px;
}

@media (max-width: 800px) {
    * {
        background-color: black;
    }
    .green-header, p, label, form, input,.input-form{
        background-color: #0C0F0E;
    }
}

.login-container {
    display: flex;
    height: 100vh;
    flex-direction: row;
    background-color: #0C0F0E;
}

.login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 6.1vw;

    background-color: #0C0F0E;

}

.green-header {
    display: flex;
    justify-content: flex-start;
}

.login-form h2 {
    background: linear-gradient(180deg, #01f663 0%, #00e4fe 100.01%);
    background-clip: text;
    color: transparent;
    font-weight: 400;
    font-family: Oxanium;
    font-size: 42px;
    padding-bottom: 20px;
    margin-bottom: 18px;
    /*height:0.16vw;*/
    /*width:auto;*/
}

.login-form form {
    width: 80%;
    max-width: 400px;
    font-size: 12px;
}

.login-form label {
    display: block;
    margin-bottom: 18px;
    color: var(--Primary-White---50, rgba(255, 255, 255, 0.60));
    font-family: 'Codee Sans Mono';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.693px; /* 160.584% */
}

.login-form input {
    margin-bottom: 35px;
    width: 100%;
    padding: 10px;
    background-color: white;
    color: var(--Primary-White---50, rgba(255, 255, 255, 0.60));
    border-radius: 4.672px;
    border: 1.752px solid var(--Primary-Solid-Stroke-Box, rgba(69, 79, 102, 0.60));
    background: var(--Primary-Solid-Grey-Box, rgba(7, 17, 18, 0.80));
}

input:focus {
    outline: none; /* Ensure no outline is added */
}

.login-form .error {
    color: red;
    margin-bottom: 20px;
}

.login-form button {
    margin-top: 20px;
    width: 100%;
    padding: 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4.672px;
    border: 2px solid rgba(158, 255, 204, 0.4); /* Light green border with transparency */
    background: #071112;
    transition: all 0.3s ease; /* Smooth transition */

}

.login-form button:hover {
    background-color: #01F66312;
}

.enter {
    background-color: rgba(0, 0, 0, 0);
    width: 70px;
    height: auto;
}

.login-image {
    flex: 1;
}

.login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

p {
    margin-bottom: 60px;
    font-size: 16px;
}

@media (min-width: 750px) {
    .mobile-image {
        display: none;
    }
}

/* Media query for screens smaller than 750px */
@media (max-width: 750px) {
    .login-container {
        flex-direction: column;
    }

    .login-image {
        display: none;
    }

    .login-form {
        width: 100%;
        max-width: 400px;
        margin: auto;
    }

}

@media (max-width: 550px) {
    .mobile-image {
        margin-left: -40px;
        width: 116%;
        height: 26%;
    }

    .login-form .mobile-image image {
        width: 120%;
        height: auto;
    }

    .login-form {
        /*padding-left: 40px;*/
        padding-right: auto;
        margin-left: 0px;
        margin-top: -260px;
        padding-left: 4.3vw;
    }

    .login-form label {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .login-form h2 {
        font-size: 30px;
        margin-bottom: 4px;
        margin-top: 32px;

        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 33px */
    }

    .login-form input {
        margin-bottom: 20px;
        width: 91.2vw;
    }

    p {
        max-width: 420px;
        font-size: 12px;
        margin-bottom: 41px;
        letter-spacing: -1.44px;
    }

    .login-form button {
        margin-top: 20px;
        width: 91.2vw;
        padding: 13px;
    }

    .enter {
        width: 59px;
        height: 26px;
    }

    .login-container {
        display: flex;
        height: 100vh;
        flex-direction: column;
        background-color: #0C0F0E;
    }


}

body {
    margin: 0;
    background-color: #121212;
    color: #FFFFFF;
}

.nav-link {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #FFFFFF99;
    margin: 0 5px;
    text-decoration: none;
    padding-bottom: 5px;
    transition: all 0.3s ease;
    padding-top: 23px;
}

@media (max-width: 600px) {
    .nav-link {
        font-size: 12px;
        padding-top: 0px;
        padding-left: 0px;
        padding-bottom: 0;
    }

    .nav-link:first-child {
        margin-top: 20px;
    }

    .nav-link.active-link {
        text-decoration: underline;
    }
}

.nav-link:first-child {
    position: relative;

}

.nav-link.active-link {
    color: #FFFFFF;
}

.content {
    padding: 0px;
}

.table-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.refresh-button,
.download-button {
    background: none;
    border: none;
    color: #00e4fe;
    cursor: pointer;
    font-size: 18px;
}

.refresh-button:hover,
.download-button:hover {
    color: #00e4fe;
}

/* Styles for the password input wrapper */
.password-input-wrapper {
  position: relative;
}

.password-input-wrapper input {
  padding-right: 40px; /* Space for the eye icon */
}

.eye-icon {
  position: absolute;
  right: 10px;
  top:26%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888; /* Adjust color as needed */
}
@media (max-width: 768px) {
.password-input-wrapper  {
    background-color: #0C0F0E;
}

}

.LoadingImage {
    height: 100px;
    width:100px;
  animation: spin 7s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
