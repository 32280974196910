@import url('https://fonts.googleapis.com/css2?family=Codee+Sans+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --font-family: "Poppins", sans-serif; /* Updated to Poppins */
}


.sideMenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 210px);
  height: 100vh;

  /* semi-transparent black background */
  background-color: transparent;

  /* Just under the side menu container */
  z-index: 9998;
}

.sideMenuContainer{
    padding-top: 1480px;
 width: 153px;
    height: 2900px;
    background:#0D0F0E;
    z-index: 99;
    transform: translateX(-100%); /* hidden by default */
  transition: transform 1s ease-in-out;
}

.sideMenuContainer.open {
  transform: translateX(0); /* slide in from the left */
}

.menuItem{
        background:#0D0F0E;
    font-family: var(--font-family);
    color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 110%; /* 15.4px */
    margin-bottom: 45px;
}
.menuItem img{
        background:#0D0F0E;
    padding-right: 12px;
    padding-left: 17px;

}
.selected{
    color:#6CE3C4;
}